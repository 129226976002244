
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        
@mixin svg-color-class($class, $color)
  &.#{$class}
    path,
    g,
    circle
      fill: $color

.svg-icon
  display: inline-block
  width: 16px
  height: 16px
  color: inherit
  vertical-align: middle
  fill: none
  stroke: currentColor

.svg-fill
  fill: currentColor
  stroke: none

  *
    fill: currentColor

.ui.svg
  &.rotate-up
    transform: rotate(0deg)

  &.rotate-right
    transform: rotate(90deg)

  &.rotate-down
    transform: rotate(180deg)

  &.rotate-left
    transform: rotate(-90deg)

  &.dark
    path,
    g
      fill: $primary-color

  &.dark-grey
    path,
    g
      fill: #80848B

  &.grey
    path,
    g
      fill: #969AA0

  &.success,
  &.green
    path,
    g
      fill: $green-color

  &.completed
    path,
    g
      fill: $checklist-counter-completed-color

  &.light-grey
    path,
    g
      fill: #BBBFC2

  &.theme
    path,
    g
      fill: $theme-color

  &.red
    path,
    g
      fill: $red-color

  &.status-blue
    path,
    g
      fill: $moderate-blue-color

  &.dark-to-green
    path,
    g
      fill: $primary-color

    &:hover
      path,
      g
        fill: $green-color

  @include svg-color-class(theme-blue, $theme-blue-color)

  @include svg-color-class(primary, $primary-color)

  &.white
    *
      fill: #ffffff !important

  &.bali-hai
    path,
    g
      fill: #7A93A8

  &.error
    path,
    g
      fill: $error-color

  &.light-success
    *
      fill: $success-color !important

  &.dark-success
    *
      fill: #5B986C !important

  &.green
    *
      fill: $green-color

  &.warning
    *
      fill: $ligntorange-color

  &.orange
    *
      fill: $orange-color

  /* size modifiers */
  &.svg--size_small
    font-size: 0.7em

  &.svg--size_smaller
    font-size: 0.8em

  &.svg--size_larger
    font-size: 1.2em
  /* END: size modifiers */

  /* rotation modifier */
  &.svg--rotate_up
    transform: rotate(0deg)

  &.svg--rotate_right
    transform: rotate(90deg)

  &.svg--rotate_down
    transform: rotate(180deg)

  &.svg--rotate_left
    transform: rotate(-90deg)
  /* END: rotation modifier */
