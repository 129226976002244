@use "sass:color"

// -------- WOUB 2.* PALETTE ---------

$color-shamrock: #00CE8D

// -------- WOUB 1.* PALETTE ---------

$body-bg-color: #f6f8fa
$green-color: #30B15D
$orange-color: #ff805d
$white-color: #ffffff
$dirty-white-color: #FBFBFB

$black-color: #000000
$bali-hai-color: #859DB3
$theme-color: $orange-color
$theme-blue-color: #0C8BFF
$primary-color: #34335C
$slategray-color: #5E6C84
$lavander-color: #E9EFF4
$smoke-color: #EDEEF0
$darkgray-color: #999da2
$gray-color: #7F848B
$ligntorange-color: #FFAC00
$red-color: #FF2724

$error-color: #FB6C88
$success-color: #4FEEAC
$info-color: #7A93A9
$info-light-color: #F0F3F6

$blue-color: #0000EE
$blue-light-color: #4E8EFB
$moderate-blue-color: #5071C5

$top-bar-height: 5em
$nav-panel-width: 18em
$modal-shadow: -0.8em 0.95em 1.5em rgba(45, 68, 126, 0.18)
$modal-z-index: 6
$modal-header-border-color: #ECEFF3

$sm-resolution: 768px
$md-resolution: 992px
$lg-resolution: 1200px
$ex-resolution: 320px
$extra-lg-resulution: 1700px
$full-hd-resolution: 1920px

$screen-sm: 768px
$screen-lg: 1440px

// --------- BASE ---------

$base-border-radius: 0.2em
$base-border-color: #CBD2D8
$base-border-color-light: #E8E8E8
$base-text-color: $primary-color
$base-animation-duration: 0.3s
$base-animation-timing-function: ease-in-out

// ------- END BASE -------

// -------- PAGES ---------

$page-container-padding-x: 2em

// ------- END PAGES ------

$input-borders: 0.07rem
$input-border-color: #B2C0CC
$input-border-style: $input-borders solid $input-border-color
$input-border-radius: 0.3rem
$input-border-radius-rounded: 10rem
$input-border-radius-dropped: 1rem

$input-radio-border-color: $base-border-color

$switch-shadow-color: #c1bfbf

$placeholder-color: $slategray-color

$link-color: $blue-color
$link-color-info: $gray-color

$breadcrumb-background-color-hover: #D4DDE4

$border-table-color: #CBD2D8


$table-row-height: 2.8em
$table-header-cell-background-color: #F8F8F8
$table-border-width: 1px
$table-border-color: $border-table-color
$table-border-style: solid
$table-border: $table-border-width $table-border-style $table-border-color
$table-z-index-row-hover: 4
$table-z-index-header: 5

$hint-text-color: $gray-color

$scrollbar-size: 0.55em

$control-green-color: $green-color
$control-green-color-hover: #5BC17E
$control-background-color-disabled: #EFEFEF
$control-border-color-disabled: #DBDDE0

$icon-button-background-color-hover: #E9EFF4
$icon-button-background-color-hover-darker: #CED8E1

$drop-item-background-color-hover: $lavander-color

$avatar-select-placeholder-background-color: rgba(0, 0, 0, 0.4)
$avatar-border-color: $white-color

$zoom-controls-background: transparentize($black-color, 0.4)
$zoom-controls-border-radius: 0.1em

$tag-text-color: $white-color
$tag-border-radius: 0.2em
$tag-background-color-success: #00DC3A
$tag-background-color-error: #FF4600

$edit-field-background-color-hover: $lavander-color
$edit-field-border-radius: $base-border-radius

$management-info-table-amount-width: 9em
$management-info-table-unit-width: 2.5em
$management-info-table-price-font-size: 0.9em
$management-info-table-row-bg-color-hover: #D4DDE5


$registrations-table-background-color: $white-color
$registrations-table-background-color-hover: #F2F5F8
$registrations-table-border-width: 0.07em
$registrations-table-border-style: solid
$registrations-table-border-color: $border-table-color
$registrations-table-border: $registrations-table-border-width $registrations-table-border-style $registrations-table-border-color


$contact-border-color-secure: $input-border-color
$contact-background-color-secure: $body-bg-color // #F6F8FA


$card-border-color: $base-border-color
$card-background-color: $dirty-white-color
$card-background-color-light: $white-color
$card-image-background-color: $white-color

$section-card-border-color: #D1D8DD
$section-card-title-font-size: 1.15em
$section-card-content-additional-block-width: 380px

$card-border-radius: 4px
$card-background-color: $white-color
$card-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.02)
$form-editor-input-background-color: transparent
$form-editor-input-background-color-hover: #f2f7fc

$form-responses-background-color: $form-editor-input-background-color-hover

$alert-background-color: $moderate-blue-color
$alert-text-color: $white-color
$alert-background-dark-purple: #1E1D4B

$checklist-item-background-color: $white-color
$checklist-item-background-color-hover: rgba(233, 239, 243, 0.5)
$checklist-item-background-color-checked: #F2F5F8

$checklist-counter-completed-color: #61CE4C

$user-info-menu-item-background-color-hover: color.mix($lavander-color, $white-color, 60%)

$shadow-background-color: rgba(#000000, 0.05)

$scrollbar-color: #9BA3AA
$subtitle-color: #7A93A8

$bar-search-text-color: $subtitle-color

$forms-table-row-background-color-hover: #f7fafc

// -------- PLANNING ---------
// TODO: replace all global planning values with that
$planning-background-color: $white-color

$planning-button-background-color-hover: $smoke-color
$planning-button-background-color-blue: $theme-blue-color
$planning-button-background-color-blue-hover: #6DB9FF

$planning-holyday-background-color: #F8F8F8
$planning-top-bar-background-color: $white-color
$planning-overlay-background-color: rgba(#303235, 0.8)
$planning-sidebar-width: 20em
$planning-sidebar-width-wide: 32em
$planning-header-height: 5em
$planning-border-width: 0.07em
$planning-border-color: $base-border-color-light
$planning-border: $planning-border-width solid $planning-border-color
$planning-border-dotted: $planning-border-width dotted $planning-border-color
$planning-current-time-line-color: #FD7850
$planning-group-background: #F2F5F8
$planning-data-item-background-info: #afb6bc
$planning-data-item-border-color-selected: #2B9AFF
$planning-tooltip-background: rgba(#6D7577, 0.9)
$planning-cell-hover-background: #EDEEF0
$planning-selection-background: rgba(#D2D2D2, 0.6)
$planning-loader-background: $theme-color

$planning-data-item-height-checklist: 2em
$planning-data-item-height-checklist-item: 2.3em
$planning-data-item-height-resource-item: 2.3em
$planning-data-item-margin-checklist: 0.45em
$planning-data-item-margin-checklist-item: 0.3em
$planning-data-item-margin-resource-item: 0.3em

$planning-row-height-resource: 3em

$planning-z-index-header: 7
$planning-z-index-sidebar: 6
$planning-z-index-shadow: 6

$planning-z-index-overlay: 10
$planning-z-index-cell-hover: 2
$planning-z-index-selection: 4
$planning-z-index-data-item: 5
$planning-z-index-data-item-upper: 25
$planning-z-index-current-time: 3
$planning-z-index-loader: $planning-z-index-header + 1
$planning-z-index-scrollbar: 6
// ------- END PLANNING ------

$chat-border-color: #e8e8e8
$chat-border: 1px solid $chat-border-color

$resizer-color: #4F89F7

$badge-size: 0.75rem // 12px
$badge-color-offline: #d9d9d9
$badge-color-online: #00CE8D

@mixin placeholder
  &::-webkit-input-placeholder
    @content

  &:-moz-placeholder
    @content

  &::-moz-placeholder
    @content

  &:-ms-input-placeholder
    @content

@mixin fill-height
  display: flex
  flex-direction: column
  overflow: hidden
  height: 100%
  width: 100%
  min-height: 0

@mixin full-height
  flex-direction: column
  overflow: hidden
  height: 100%
  width: 100%
  min-height: 0

@mixin auto-height
  overflow: auto
  height: auto
  width: auto
  min-height: auto

@mixin select-text
  -webkit-touch-callout: text
  -webkit-user-select: text
  -khtml-user-select: text
  -moz-user-select: text
  -ms-user-select: text
  user-select: text

@mixin no-select
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

@mixin text-ellipsis
  white-space: nowrap
  overflow: hidden !important
  text-overflow: ellipsis

@mixin clear-button
  border: unset
  background: unset

@mixin scrollbar-style
  width: $scrollbar-size
  height: $scrollbar-size

@mixin scrollbar-thumb-style
  background-color: #9BA3AA
  box-shadow: inset 0em 0em 10em 0em #9BA3AA
  border-radius: 10em

@mixin scrollbar-thumb-style-dark
  background-color: #7F848B
  box-shadow: inset 0em 0em 10em 0em #7F848B
  border-radius: 10em

@mixin opacity-scroll
    /* Firefox */
    scrollbar-width: none

    /* Internet Explorer 10+ */
    -ms-overflow-style: none

    /* WebKit */
    &::-webkit-scrollbar
      width: 0px
      height: 0px

@mixin svg-color($color)
  &
    path,
    g,
    circle
      fill: $color

@mixin visually-hidden
  margin: -1px
  padding: 0
  height: 1px
  width: 1px
  overflow: hidden
  position: absolute
  white-space: nowrap
