
          @use "sass:math";
          @use "sass:color";
          @use "sass:list";
          @use "sass:selector";
          @use "@/assets/sass/config.sass" as *;
        

.media-onedrive-integration-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
  pointer-events: none;

  .media-onedrive-integration-info__integration-name {
    margin-left: 0.4rem;
    color: #30b15d;
  }
}
