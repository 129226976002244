
          @use "sass:math"
          @use "sass:color"
          @use "@/assets/sass/config.sass" as *
        

@import "./styles/Item"

.checklists-block-item
  position: relative
  z-index: 0

  &:has(.form-edit-name)
    z-index: auto

  .checklists-block-item__header
    position: relative

    &:hover .checklists-block-item__checkbox
      visibility: visible

  .checklists-block-item__checkbox
    position: absolute
    padding-right: 0.5em
    margin-top: 0.5em
    right: 100%
    top: 0
    visibility: hidden

  .checklists-block-item__placeholder
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: color.mix(#000, #fff, 5)
    transition: 0.5s all
    border-radius: 0.2rem
    z-index: 1

  .checklists-item-wrap
    margin: 0.6em 0
    position: relative
    flex-direction: row
    align-items: center

    &__checkbox
      position: absolute
      padding-right: 0.5em
      right: 100%
      top: 0
      bottom: 0
      display: flex
      align-items: center
      visibility: hidden

    &.ghost
      position: fixed

    &:hover .checklists-item-wrap__checkbox,
    &.active .checklists-item-wrap__checkbox
      visibility: visible

    &.active
      .checklist-item .border-container
        border: 0.18em solid #b2c0cc !important

  &.active .checklists-block-item__checkbox
    visibility: visible

  &.active .checklists-item-wrap__checkbox
    visibility: visible

.checklists-block-item--view_compact
  .options-rectangle
    position: relative

  .options-rectangle__buttons
    background: #e9eff4
    padding: 0.1em
    position: absolute
    right: 0
    bottom: -1.8em

  &.checklists-block-item > .body > .list:not(:empty)
    padding-top: 1.3em

  .checklists-block-item__checkbox,
  .checklists-item-wrap__checkbox
    padding-right: 0.3em

.checklists-block-item--preview
  border-radius: 0.2rem
  padding: 1em
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.27)

  &.checklists-block-item--view_compact
    padding: 0 2em
