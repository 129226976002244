/* like bootstrap or tailwind */
.text-primary {
  color: $primary-color;
}
.text-blue {
  color: $theme-blue-color;
}
.text-info {
  color: $info-color;
}
.text-green {
  color: $green-color;
}
.text-error {
  color: $error-color;
}
.text-hint {
  color: $hint-text-color;
}
.text-small {
  font-size: 0.8em;
}

.font-bold {
  font-weight: 700;
}

.width-full {
  width: 100%;

  // TODO: remove the selector when pure-marking rules are removed
  &.ui {
    width: 100% !important;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-ellipsis {
  @include text-ellipsis;
}
