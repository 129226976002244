@import "~@/ui-components/controls/scrollbar/styles.sass"

$default-font: NunitoSans, NotoColorEmoji

html,
body
  overscroll-behavior-x: none

.save-format
  ul,
  ol
    padding-left: 2em

    li
      padding-left: unset !important

  u
    text-decoration: underline

  s
    text-decoration: line-through

html,
body,
#app,
.page
  height: 100%

body
  background-color: $body-bg-color
  // font-size: 1.8vh
  color: $primary-color
  font-size: 14px
  overflow: hidden !important

@media (min-width: $screen-sm + 1px)
  body
    font-size: 1.6vh


@media (min-width: $screen-lg)
  body
    font-size: 15px


[disabled]
  pointer-events: none

input[type=number]
  -moz-appearance: textfield

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input,
textarea
  @include placeholder
    color: $placeholder-color
    opacity: 1

#app,
textarea,
select,
input,
button
  font-family: $default-font, sans-serif
  font-size: 1em

button.link
  background: unset
  border: unset

.hidden,
[hidden] 
  display: none !important

[contenteditable]
  -webkit-user-select: text
  user-select: text

// inspired by https://github.com/contentco/quill-emoji/issues/49
.ql-emoji
  display: inline-block
  vertical-align: text-top

.link
  font-size: 1em

  &.theme
    color: $theme-color

  &.bold
    font-weight: 700

  &.danger
    color: #fb6c88

  &.primary
    color: $primary-color

  &.underline
    border-bottom: 0.05em solid $theme-color

.group-inputs
  input:not([type="checkbox"]):not([type="radio"])
    display: block
    width: 100%
    padding: 0.7em 1em
    border-radius: 0.3rem
    font-family: 'NunitoSans'
    font-size: 0.9em
    color: #34335C

    &:not(:first-child)
      margin-top: 0.7em

.group-input
  &:not(:first-child)
    margin-top: 0.7em


// FADE ANIMATION

.fade-enter-active,
.fade-leave-active
  transition-property: opacity
  transition-duration: 0.5s

.fade-enter-active
  transition-delay: 0.5s

.fade-enter-from,
.fade-leave-active
  opacity: 0


// IMMEDIATE FADE ANIMATION

.immediate-fade-enter-active,
.immediate-fade-leave-active
  transition-property: opacity
  transition-duration: 0.5s

.immediate-fade-enter-from,
.immediate-fade-leave-active
  opacity: 0


// COMPONENT INITIAL ANIMATION

.component-enter-active,
.component-leave-active
  transition-property: opacity
  transition-duration: 0.2s

.component-enter-active
  transition-delay: 0.2s

.component-enter-from,
.component-leave-active
  opacity: 0



.flex-page-container
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  overflow: hidden

.hide-block
  opacity: 0
  visibility: hidden
  pointer-events: none

.no-background
  background: url('~@/assets/img/no-background.svg') 0 0 / 16px 16px #f2f2f2

.animation-spin
  animation: spin 3s linear infinite

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)


.block-events
  pointer-events: none

.pointer-btn
  cursor: pointer

  &>*
    pointer-events: none

.gradient-preloader
  $speedAn: 1.5s

  .item
    display: flex
    // margin: -0.3em
    // font-size: 2em

  .square
    width: 1em
    height: 1em
    // margin: 0.3em
    background-color: #E7EFF4
    border-radius: 0.2rem

    -webkit-animation: AnimationName $speedAn ease infinite
    -moz-animation: AnimationName $speedAn ease infinite
    animation: AnimationName $speedAn ease infinite

  .rectangle
    height: 1em
    width: 100%
    flex: 1
    // margin: 0.3em
    background-color: #E7EFF4
    border-radius: 0.2rem

    -webkit-animation: AnimationName $speedAn ease infinite
    -moz-animation: AnimationName $speedAn ease infinite
    animation: AnimationName $speedAn ease infinite

@-webkit-keyframes AnimationName
  0%
    background-color: darken(#E7EFF4, 0%)

  50%
    background-color: darken(#E7EFF4, 3%)

  100%
    background-color: darken(#E7EFF4, 0%)

@-moz-keyframes AnimationName
  0%
    background-color: darken(#E7EFF4, 0%)

  50%
    background-color: darken(#E7EFF4, 3%)

  100%
    background-color: darken(#E7EFF4, 0%)

@keyframes AnimationName
  0%
    background-color: darken(#E7EFF4, 0%)

  50%
    background-color: darken(#E7EFF4, 3%)

  100%
    background-color: darken(#E7EFF4, 0%)

/* always present

.expand-transition
  transition: all 2s ease
  height: 30px
  padding: 10px
  background-color: #eee
  overflow: hidden

/* .expand-enter-from defines the starting state for entering
/* .expand-leave-from defines the ending state for leaving

.expand-enter-from,
.expand-leave-from // *-leave-to maybe?
  height: 0
  padding: 0 10px
  opacity: 0

.slide-enter-from,
.slide-leave-to
  transform: scaleY(0)

//@media screen and (max-width: 1919px)
  body
    font-size: 2.2vh

.slides-left-leave-active,
.slides-left-enter-active
  transition: 0.5s transform

.slides-left-enter
  transform: translate(-100%, 0)

.slides-left-leave-to
  transform: translate(-100%, 0)

.slides-right-leave-active,
.slides-right-enter-active
  transition: 0.5s transform

.slides-right-enter
  transform: translate(100%, 0)

.slides-right-leave-to
  transform: translate(100%, 0)


.slides-top-leave-active,
.slides-top-enter-active
  transition: 0.5s

.slides-top-enter
  transform: translate(0, 100%)

.slides-top-leave-to
  transform: translate(0, 100%)


.slides-bottom-leave-active,
.slides-bottom-enter-active
  transition: 0.5s

.slides-bottom-enter
  transform: translate(0, -100%)

.slides-bottom-leave-to
  transform: translate(0, -100%)



.expand-to-enter-active,
.expand-to-leave-active
  transition: height 1s ease-in-out
  overflow: hidden

.expand-to-enter-from,
.expand-to-leave-to
  height: 0


.fadeHeight-enter-active, .fadeHeight-leave-active
  transition: all 0.2s
  max-height: 230px

.fadeHeight-enter-from, .fadeHeight-leave-to
  opacity: 0
  max-height: 0px

@import "./file-ext.sass"

// ANIMATIONS


// @keyframes spin
//   100%
//     -webkit-transform: rotate(360deg)
//     transform:rotate(360deg)
// END ANIMATIONS

.tooltip
  @include no-select
  position: absolute
  top: 0
  right: 0
  box-shadow: 0 0em 0.08em 0.1em rgba(181, 181, 181, 0.2)
  border-radius: 0.3rem
  background-color: #ffffff

  &:after
    clip: rect(0em, 2em, 2em, 0em)
    content: ""
    position: absolute
    display: block
    width: 1em
    height: 1em
    background: #fff
    right: 0.2em
    bottom: -0.5em
    transform: translateX(-50%) rotate(45deg)
    box-shadow: 0 0em 0.08em 0.1em rgba(181, 181, 181, 0.2)
    transition: transform 200ms ease-out
  
  &.top
    &:after
      transform: rotate(225deg)
      top: -0.5em

  &.left
    &:after
      transform: rotate(135deg)
      top: 0.5em
      right: unset
      left: -0.5em


// MODALS

.modal
  position: absolute
  top: $top-bar-height
  left: 0
  right: 0
  bottom: 0
  display: flex
  max-height: 100%
  padding: 5em 0
  transition: opacity 0.2s
  z-index: $modal-z-index

  @extend .ui, .scrollbar-wrapper, .scrollbar-wrapper--dark

  &.prio
    z-index: 10

  &.full
    position: fixed
    top: 0
    z-index: 10
  
  &.shadow
    background-color: rgba(127, 132, 139, 0.7)

  .loaded
    &.loaded-active
      opacity: 1

      &:after,
      &:before
        position: absolute
        content: ""
        left: 0
        right: 0
        top: 0
        bottom: 0

      &:before
        z-index: 20
        background-image: url(~@img/woub-load.gif)
        background-position: center
        background-repeat: no-repeat
        background-size: 7em

      &:after
        background-color: #ffffff
        opacity: 0.6
        z-index: 10

  &>.layout
    margin: auto

  button.close-circle-grey
    padding: 1em

    position: absolute
    top: 1.5em
    right: 1.5em

    border-radius: 100%
    border: unset
    
    background-color: #e9eff4
    background-image: url(~@img/modal-close.svg)
    background-size: 0.75em
    background-position: center
    background-repeat: no-repeat

// END MODALS


// EMOJI

.emoji.picker
  position: absolute
  bottom: 5em
  right: 1em
  transition: 0.2s

  // &:not(.show)
  //   transform: translate(100%, 0)
  //   opacity: 0
    
  &>.emoji-mart
    border: $input-border-style
    border-radius: 0.3rem

    font-size: inherit
    width: 23.5em !important
    height: 30em !important

    .emoji-mart-scroll
      &::-webkit-scrollbar
        width: 0.5em !important
        height: 0.5em !important

      &::-webkit-scrollbar-thumb
        background-color: #838c95 !important
        box-shadow: inset 0px 0px 120px 0px rgba(235, 238, 243, 0.25) !important
        border-radius: 0.2em !important

    &>.emoji-mart-bar
      border-color: $input-border-color !important
      border-width: 0em 0em $input-borders 0em

      &>.emoji-mart-anchors
        padding: 0em

        svg
          width: 1.1em
          height: 1.1em

        .emoji-mart-anchor-bar
          display: none

        .emoji-mart-anchor
          padding: 0.8em 0em
          cursor: pointer
          color: #858585

          &.emoji-mart-anchor-selected
            background-color: fade-out($theme-color, 0.95)

  .emoji-mart-scroll
    padding: 0em 0.3em

  .emoji-mart-category-label
    color: $primary-color
    font-weight: bold

    span
      padding: 0.5em

  .emoji-mart .emoji-mart-emoji
    //padding: 1em
  
  .emoji-mart-category
    .emoji-mart-emoji
      padding: 0.5em
      font-size: 1.8vh
      cursor: pointer
      transition: 0.2s

      &:before
        display: none

      &:hover
        opacity: 0.7

      span
        font-size: 1.5em !important
        width: 1em !important
        height: 1em !important
        cursor: pointer

  .emoji-mart-bar>.emoji-mart-preview
    display: none

  .emoji-mart-search
    padding: 0.2em 0.7em
    margin: unset

    input
      border: $input-border-style
      border-radius: 0.3rem
      padding: 0.5em 1em
      margin: 0.5em 0em
      font-size: 1em


.emoji-type-native,
.emoji-mart,
.ql-editor,
.ui.input textarea
  font-family: $default-font !important

// .quill > .ql-container > .ql-editor.ql-blank::before
//   font-size: 20px
//   color: red

.quill-editor
  .ql-container
    font-size: 1em

.ql-editor.ql-blank:before
  color: $placeholder-color
  opacity: 1
  font-size: 1em

// END EMOJI


// LOADED

.loaded
  @include no-select
  $loaded-speed: 0.6s

  position: relative
  transition: opacity $loaded-speed

  &.loaded-active
    opacity: 0.4
    overflow: hidden
    pointer-events: none

  &.spin:before
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    display: block

    z-index: 2
    transition: $loaded-speed all

    background-image: url(~@img/ShapeSync.svg)
    background-size: 3em
    background-position: center
    background-repeat: no-repeat

    -webkit-animation: spin 4s linear infinite
    -moz-animation: spin 4s linear infinite
    animation: spin 4s linear infinite

  &.spin:not(.loaded-active):before
    visibility: hidden
    opacity: 0
    width: 0em
    height: 0em

  &.page-loader,
  &.white-logo,
  &.component-loader
    &.loaded-active
      opacity: 1
    
      &:after,
      &:before
        position: absolute
        content: ""
        left: 0
        right: 0
        top: 0
        bottom: 0

      &:before
        z-index: 2
        background-image: url(~@img/woub-load.gif)
        background-position: center
        background-repeat: no-repeat
        background-size: 7em

      &:after
        background-color: #ffffff
        opacity: 0.6
        z-index: 1

  &.component-loader
    &.loaded-active
      &:before
        background-size: 2em

  &.page-loader
    &.loaded-active
      &:after
        opacity: 1

@-moz-keyframes spin
  100%
    -moz-transform: rotate(360deg)

@-webkit-keyframes spin
  100%
    -webkit-transform: rotate(360deg)



@-moz-keyframes pulse
  0%
    opacity: 1

  50%
    opacity: 0

  100%
    opacity: 1

@-webkit-keyframes pulse
  0%
    opacity: 1

  50%
    opacity: 0

  100%
    opacity: 1

@keyframes pulse
  0%
    opacity: 1

  50%
    opacity: 0

  100%
    opacity: 1

// END LOADED



// tooltip
.tooltip-p
  &::after 
    content: ''
    display: block

.tooltip-m
  position: fixed
  z-index: 99999999
  padding: 0.5em
  background-color: fade-out($primary-color, 0.2)
  border-radius: 0.2em
  color: #ffffff

  white-space: nowrap

  transition: 0.5s opacity
  opacity: 1

  font-family: Geneva, Arial, Helvetica, sans-serif
  font-family: $default-font
  font-size: 1em
  white-space: pre-wrap
  word-break: break-word
  overflow: hidden
  pointer-events: none

  &.tx-overflow
    &::after
      content: ''
      position: absolute
      bottom: 0
      left: 0
      right: 0
      height: 3em
      background: linear-gradient(0deg, fade-out($primary-color, 0.1) 0%,  fade-out($primary-color, 0.7) 50%, transparent 100%)

  &.empty
    top: 0
    left: 0
    opacity: 0

  &[size="small"]
    font-size: 0.8em


/**
  * Dragg col derictive
  */

.table-drag-shadow
  position: fixed
  width: 10em
  height: 2em
  background-color: $shadow-background-color
  pointer-events: none

.splitpanes--theme_blue-splitter
  user-select: none

  .splitpanes__pane
    overflow: initial
    transition: none

  > .splitpanes__splitter
    &::after
      border-left: 2px solid transparent !important
      transition: border 0.15s ease-in-out

      @at-root
        .splitpanes--dragging#{&}
          border-color: $resizer-color !important

    &:hover
      &::after
        border-color: $resizer-color !important

  &.splitpanes
    .splitpanes__splitter
      box-sizing: border-box
      position: relative
      flex-shrink: 0

      &:before,
      &:after
        width: 10px !important
        content: ""
        position: absolute

  &.splitpanes--vertical,
  .splitpanes--vertical
    > .splitpanes__splitter
      margin-left: -1px

      &:before
        margin-left: -8px

@import "./utilities.scss"
